import { ReactNode } from 'react';
import {
  AuthProviderProps,
  AuthTokenProps,
  SessionInterfaceProps
} from './shell';
export type StratusTokenPayload = {
  tenant_id: string;
  sub: string;
  stratus_id: string;
  wpid: string;
  corId: string;
  idp_id: string;
  iss: string;
  access_policies: Record<string, string>;
  client_id: string;
  ca_exp: number;
  fq_tenant_id: string;
  nbf: number;
  azp: string;
  scope: string;
  exp: number;
  iat: number;
  jti: string;
  idp_type: string;
};

export type TokenProviderProps = {
  children: ReactNode;
  authService: AuthProviderProps;
  sessionInterface: SessionInterfaceProps;
  authToken: AuthTokenProps;
};

export type TokenProviderType = {
  getAuthorizationToken: () => Promise<string>;
  getUserId: () => Promise<string>;
  redirectToLogin: () => void;
};

export enum Role {
  HP = 'hp',
  RESELLER = 'reseller',
  USER = 'user'
}
