import { DirectionProvider } from '@veneer/core';
import { ThemeProvider } from '@veneer/theme/';
import React, { useEffect, useMemo, useState } from 'react';
import resources from 'src/assets/locale';
import ShellRootProvider from 'src/contexts/ShellRoot/ShellRootProvider';
import {
  InterfacesType,
  ShellRootComponentProps,
  TranslatorFunctionType
} from 'src/types/shell';
import projectNames from './configs/projectNames';
import { MainWrapper } from 'src';
import TokenProvider from 'src/contexts/TokenProvider';

export default function Root({ properties }: ShellRootComponentProps) {
  const [t, setT] = useState<TranslatorFunctionType>();
  // You can find all Shell´s props here. See "src/types/shell" for more details.
  const { v1, v2 } = window.Shell as InterfacesType;
  const interfaces = {
    v1,
    v2
  };

  const directionValue =
    v1?.localization?.useReactGetLanguageDirection?.(React);

  const { themeProviderProps, userThemeMode } = useMemo(() => {
    return {
      themeProviderProps: v1?.theme?.getThemeProviderProperties?.(),
      userThemeMode: v1?.theme?.getUserThemeMode?.()
    };
  }, [v1?.theme]);

  // Create a translator function by providing the resource files
  useEffect(() => {
    v1.localization
      .createTranslatorFunction(resources)
      .then((v) => setT(() => v));
  }, [v1]);

  // Wait for the translator function creation to show the content
  if (!t) {
    return null;
  }

  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <DirectionProvider direction={directionValue}>
        <ThemeProvider
          {...themeProviderProps}
          mode={userThemeMode}
        >
          <ShellRootProvider {...{ interfaces, t, properties }}>
            {/* Token provider is responsible for returning a valid token to be used to call back-end services*/}
            <TokenProvider
              authService={interfaces.v1.authProvider}
              sessionInterface={interfaces.v1.sessionInterface}
              authToken={interfaces.v1.authToken}
            >
              <MainWrapper />
            </TokenProvider>
          </ShellRootProvider>
        </ThemeProvider>
      </DirectionProvider>
    </section>
  );
}
